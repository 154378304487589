.field-sidebar {
  position: fixed;
  margin-top: 50px;
  top: 0;
  right: 0;
  bottom: 0;
  width: 500px;
}
.form-preview {
  background-color: white;
  width: calc(100% - 505px);
}
.button-header {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
}
.form-builder-header {
  margin-right: 505px;
}
