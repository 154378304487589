html,
body {
  margin: 0;
  padding: 0;
  font-family: "DIN Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}
.lynx-sidebar-table {
  overflow: scroll;
}
.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 99999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #6f7e88;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

.content-wrapper-fixed {
  position: fixed;
  height: 100vh;
  padding-top: 50px;
}

.content-wrapper-with-footer {
  border-top: 1px solid #ced4da;
  margin-top: 50px;
  position: fixed;
  height: calc(100% - 85px);
  overflow-y: auto;
  scrollbar-width: auto;
  width: 100%;
  background-color: #fcfcfc;
}

@media (max-width: 576px) {
  .content-wrapper-with-footer {
    height: calc(100% - 100px);
  }
}

.content-wrapper {
  border-top: 1px solid #ced4da;
  margin-top: 50px;
  position: fixed;
  height: calc(100% - 50px);
  overflow-y: auto !important;
  scrollbar-width: auto;
  width: 100%;
  background-color: #fcfcfc;
}
.content-wrapper-microsite {
  position: fixed;
  height: 100%;
  overflow-y: auto !important;
  scrollbar-width: auto;
  width: 100%;
  background-color: #fcfcfc;
}
.content-wrapper-no-overflow {
  border-top: 1px solid #ced4da;
  margin-top: 50px;
  position: fixed;
  height: calc(100% - 50px);
  width: 100%;
  background-color: #fcfcfc;
}

.row {
  margin-left: 0rem;
  margin-right: 0rem;
}

.padding-left-sidebar {
  padding-left: 200px;
}

.padding-right-sidebar {
  padding-right: 200px;
}

.modal-card {
  margin-bottom: 0rem;
}

.map-chart h3 {
  color: black;
}

.pointer {
  cursor: pointer;
}

.header-text {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.security-table-col1 {
  width: 5%;
  text-align: center;
  height: 20px;
  border: 1px solid grey;
}

.security-table-header {
  text-align: center;
  height: 20px;
}

.security-table-vert {
  writing-mode: vertical-lr;
  text-align: center;
  transform: scale(-1);
  vertical-align: middle;
  left: 15px;
}

.security-table-col {
  width: 18%;
  text-align: center;
  height: 60px;
  border: 1px solid grey;
}

.col-low {
  background-color: lightgreen;
}

.col-medium {
  background-color: lightyellow;
}

.col-high {
  background-color: orange;
}

.col-vhigh {
  background-color: lightcoral;
}

.map-icon {
  margin: 12px;
  color: white;
  cursor: pointer;
}

.share-icon {
  color: yellow;
}

aside.sidebar {
  position: fixed;
  margin-top: 50px;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0px;

  background-color: white;
  z-index: 99;
}

.header-button {
  float: right;
}

/* Bootstrap small */
@media (max-width: 576px) {
  .header-button-left {
    float: left;
    padding-bottom: 0.5rem;
  }
}

@media (max-width: 900px) {
  aside {
    display: none;
  }

  .padding-left-sidebar {
    padding-left: 0px;
  }

  .nav-options {
    display: none;
  }

  .account-options {
    display: none;
  }

  .lynx-modal {
    width: 100% !important;
  }

  .lynx-modal-80 {
    width: 100% !important;
  }

  #assetDashButton {
    visibility: hidden;
  }

  .header-text {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}

@media (min-width: 900px) {
  .menu-button {
    display: none;
  }

  .lynx-modal {
    width: 50% !important;
  }

  .lynx-modal-80 {
    width: 80% !important;
  }
}

.menu-button {
  font-size: 0.6875rem;
}

/* make MUI buttons respect case */
.MuiButton-root {
  text-transform: none !important;
}

/* .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ced4da !important;

} */

/* .MuiOutlinedInput-notchedOutline:focus {
  border: 1px red !important;

} */

.dsmodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.dsmodal-main {
  position: fixed;
  width: 40%;
  height: auto;
  top: 50%;
  left: 50%;
  max-height: 80%;
  transform: translate(-50%, -50%);
  overflow: scroll;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.subpage-header-row {
  height: 68px;
}
.subpage-header-row-mobile {
  height: 68px;
}
.subpage-header-row-fixed {
  height: 50px;
  position: fixed;
  top: 50px;
  width: 100%;
  z-index: 99;
  background-color: #fff;
  border-bottom: 1px solid #ced4da;
}
.subpage-header-row-breadcrumbs {
  height: 85px;
}

/* override table-react select not showing error */

.custom-select {
  -webkit-appearance: listbox !important;
}

.form-container {
  margin-top: 0.5rem !important;
}

@media (max-width: 900px) {
  .form-container {
    margin-top: 0.5rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.mapboxgl-ctrl-attrib-inner {
  display: none;
}
@media (max-width: 480px) {
  .form-header-text {
    font-weight: 500 !important;
    font-size: 1.25rem !important;
    line-height: 1.6 !important;
  }
  .subpage-header-row-mobile {
    height: 50px;
  }
}

.lynx-card-header {
  background-color: rgba(0, 0, 0, 0.004);
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
}

.lynx-card {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}
