.bottom-right-container {
  bottom: 0;
  position: fixed;
  left: 0;

  width: 100%;
  border-top: 1px solid #ced4da;
}

.footer-content {
  height: 35px;
  width: 100%;
  padding-top: 5px;
}

@media (max-width: 576px) {
  .footer-content {
    height: 50px;
  }
}
.login-container {
  min-height: calc(100vh - 85px); /* Full screen height */
  display: flex;
  margin-bottom: 35px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  background-image: url("../images/login-background.jpg"); /* Replace with your image */
  background-size: cover; /* Ensures the image covers the entire area */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents tiling */
}
.login-container::before {
  content: "";
  position: absolute;
  top: 0;
  margin-bottom: 35px;
  margin-top: 50px;
  left: 0;
  width: 100%;
  min-height: calc(100vh - 85px);
  background-color: rgba(0, 0, 0, 0.2); /* Dark overlay */
  z-index: 1;
}
.login-card {
  max-width: 400px;
  width: 100%;
  z-index: 2;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 3px solid black;
}
.ruby-card {
  z-index: 2;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 3px solid black;
}
.logo {
  width: 550px;
  display: block;
  margin: auto;
}

.sign-in-button {
  margin-top: 20px;
}

.version-text {
  margin-top: 20px;
}

@media (max-width: 576px) {
  .login-container {
    margin-bottom: 50px;
    min-height: calc(100vh - 100px);
  }
  .login-container::before {
    margin-bottom: 50px;
    min-height: calc(100vh - 100px);
  }
}
